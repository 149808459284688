// Library Imports
import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React, {useState, useEffect} from 'react';
// Page Imports
import AboutUs from './pages/aboutUs';
import Home from './pages/home';
import Tester from './pages/tester';
import JobBoard from './pages/jobBoard';
import JobDetails from './pages/jobDetails';
import Footer from "./pages/footer";
import StudyPlaces from "./pages/Blogs/studyPlaces";
import Diversify from "./pages/Blogs/diversify";
import BruceYovela from "./pages/Blogs/Bruce&Yovela";
import Volunteering from "./pages/Blogs/volunteering";
import Sponsors from "./pages/Blogs/sponsors";
import Cindy from "./pages/Blogs/Cindy";
import Libraries from "./pages/Blogs/libraries";
import StudyNash from "./pages/Blogs/StudyNash.jsx";
import StudySlump from "./pages/Blogs/studySlump.jsx";
import LinkedinRequest from "./pages/Blogs/LinkedinRequest.jsx";
import IntlResumeTips from "./pages/Blogs/IntlResumeTips.jsx";
import Hire from "./pages/Insights/Hire";
import Why from './pages/Insights/Why';
import Skills from './pages/Insights/skills.jsx';
import Blogs from './pages/blogs';
import Insights from './pages/insights';
import Companies from './pages/companies';

// Component Import
import  Navbar  from './components/Navbar/Navbar';

// Hook Imports
export const Context = React.createContext();

function App() {
  const [studentView, setStudentView] = useState(true)
  
  useEffect(() => {
    let oldView = localStorage.getItem('studentView');
    oldView = oldView == "true" || oldView == null
    setStudentView(oldView)
  }, []);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  return (
    <Router>
      <Context.Provider value = {[studentView, setStudentView]}>
        <div className="App">
          <Navbar class = "relative z-50"/>
          <Routes class = "z-30">
            <Route path='/' element={<Home/>} />
            <Route path='/companies' element={<Companies/>} />
            <Route path='/tester' element={<Tester/>} />
            <Route path='/job-board' element={<JobBoard/>} />
            <Route path='/job-details' element={<JobDetails/>} />
            <Route path='/blog-studyPlaces' element={<StudyPlaces/>} />
            <Route path='/blog-diversify' element={<Diversify/>} />
            <Route path='/blog-Bruce&Yovela' element={<BruceYovela/>} />
            <Route path='/about-us' element={<AboutUs/>} />
            <Route path='/blogs' element={<Blogs/>} />
            <Route path='/insights' element={<Insights/>} />
            <Route path='/insights-hiring' element={<Hire/>} />
            <Route path='/insights-skills' element={<Skills/>} />
            <Route path='/insights-why' element={<Why/>} />
            <Route path='/blog-volunteering' element={<Volunteering/>} />
            <Route path='/blog-sponsors' element={<Sponsors/>} />
            <Route path='/blog-Cindy' element={<Cindy/>} />
            <Route path='/blog-libraries' element={<Libraries/>} />
            <Route path='/blog-studyNash' element={<StudyNash/>} />
            <Route path='/blog-studySlump' element={<StudySlump/>} />
            <Route path='/blog-linkedinRequest' element={<LinkedinRequest/>} />
            <Route path='/blog-IntlResumeTips' element={<IntlResumeTips/>} />
          </Routes>
          <Footer></Footer>
        </div>
      </Context.Provider>
    </Router>
  );
}

export default App;
