// Image Imports
import BruceYovelaImage from '../assets/Images/BruceYovela.JPG';
import LibraryAtTheDock from '../assets/Images/LibraryAtDock1.png'
import Sequoia from "../assets/Images/SequoiaCover.jpg"
import Ashley from '../assets/Images/Ashley.jpg'
import AileenAuthor from '../assets/Images/AileenAuthor.jpeg'
import NathanAuthor from '../assets/Images/NathanAuthor.jpg'
import MohitAuthor from '../assets/Images/MohitRauAuthor.jpeg'
import SadieAuthor from '../assets/Images/SadieSaidAuthor.jpg'
import BruceYovelaAuthor from "../assets/Images/BruceYovelaAuthor.JPG"
import volunteering from "../assets/Images/volunteering.jpg"
import sponsors from "../assets/Images/sponsors.jpg"
import stephanie from "../assets/Images/stephanie.jpg"
import Cindy from "../assets/Images/Cindy.png"
import StateLib from "../assets/Images/StateLib.png"
import studyNash from "../assets/Images/studyNash2.png"
import StudySlump from "../assets/Images/StudySlump.jpg"
import LinkedinRequest from "../assets/Images/LinkedInRequest.jpg"
import ResumeTips from "../assets/Images/ResumeTips.jpg"

const blogList = [
    {
        blogID: 11,
        blogImg: ResumeTips, 
        blogTag:'Hiring', 
        blogTitle:'Resume Tips for International Students', 
        blogDescription:'Coming to Australia as an International Student is an exciting time! There’s a constant stream of new people to meet, and the opportunity to max out your learning - Every. Single. Day.',
        blogAuthorImg: SadieAuthor,
        blogAuthor: 'Sadie Said',
        blogPublishDate: '16th September 2024',
        blogLink: '/blog-IntlResumeTips'
    },
    {
        blogID:10,
        blogImg: LinkedinRequest, 
        blogTag:'Hiring', 
        blogTitle:'7 Principles to Get Anyone to Accept Your LinkedIn Request', 
        blogDescription:'Mastering LinkedIn can be a game-changer for international students. Connecting with peers and industry leaders can significantly enhance your job search and professional growth.',
        blogAuthorImg: MohitAuthor,
        blogAuthor: 'Mohit Rau',
        blogPublishDate: '16th September 2024',
        blogLink: '/blog-linkedinRequest'
    },
    {
        blogID:9,
        blogImg: StudySlump, 
        blogTag:'Lifestyle', 
        blogTitle:'How to Get Out of Your Study Slump', 
        blogDescription:'Does anyone else feel as if they blinked and it’s week seven of uni already? And unless you are a part of that tiny tiny tiny percentage of superhuman students who are somehow still bulldozing through the weeks, I’d say you’re close to or are already stuck in a study slump. But that’s totally fine because guess what, me too! If I can get myself out, you can too, so come close, let me let you in on what helped me.',
        blogAuthorImg: AileenAuthor,
        blogAuthor: 'Ailene Susanto',
        blogPublishDate: '13th September 2024',
        blogLink: '/blog-studySlump'
    },
    {
        blogID:8,
        blogImg: studyNash, 
        blogTag:'Community', 
        blogTitle:'Stint and Studynash Join Forces to Provide Migration Support to International Students in Australia', 
        blogDescription:'We are excited to announce a partnership between Stint, the community and job platform dedicated to international students and graduates in Australia, and Studynash, a new-age education and migration consulting firm.',
        blogAuthorImg: NathanAuthor,
        blogAuthor: 'Nathan Lee',
        blogPublishDate: '30th August 2024',
        blogLink: '/blog-studyNash'
    },
    {
        blogID:7,
        blogImg: StateLib, 
        blogTag:'Lifestyle', 
        blogTitle:'Best Libraries to Study At For International Students in Melbourne', 
        blogDescription:'If you’re like me, and you find the siren call of your bed or your couch too alluring to study properly, you’re probably on the lookout for a nice, quiet space to hunker down. Well don’t worry, I’ve got your back.',
        blogAuthorImg: Ashley,
        blogAuthor: 'Ashley Yeow',
        blogPublishDate: '25th August 2024',
        blogLink: '/blog-libraries'
    },
    {
        blogID:6,
        blogImg: Cindy, 
        blogTag:'Community', 
        blogTitle:'From Corporate to Startup: Navigating Career Change as an International Student in Australia', 
        blogDescription:'Changing careers can be a daunting task for anyone. The risk of losing job security alongside the change in regular income are enough to deter many people from choosing to pursue a line of work that interests them more. ',
        blogAuthorImg: Ashley,
        blogAuthor: 'Ashley Yeow',
        blogPublishDate: '23rd August 2024',
        blogLink: '/blog-Cindy'
    },
    {
        blogID:5,
        blogImg: sponsors, 
        blogTag:'Hiring', 
        blogTitle:'Wondering What Companies Can Sponsor in Australia? Check Out the List of Accredited Sponsors in 2024', 
        blogDescription:'As an international student in Australia, finding companies that sponsor international students can be challenging. To make your job search easier, Stint has reached out to the Department of Home Affairs and obtained the list of Accredited Sponsors in Australia for 2024. These companies are approved to sponsor individuals, including international students and graduates, for various visa programs, providing a potential pathway to obtaining a work visa and permanent residency.',
        blogAuthorImg: NathanAuthor,
        blogAuthor: 'Nathan Lee',
        blogPublishDate: '13th August 2024',
        blogLink: '/blog-sponsors'
    },
    {
        blogID:4,
        blogImg: volunteering, 
        blogTag:'Community', 
        blogTitle:'Unlocking the Power of Volunteering: A Must for International Students in Melbourne', 
        blogDescription:'Studying abroad is an exciting journey, and Melbourne’s vibrant culture and welcoming community make it an ideal destination for students from around the world. Want to make the most of your time here, supercharge your skills, and boost your career prospects? Dive into volunteering! Here’s why every international student in Melbourne should embrace this amazing opportunity.',
        blogAuthorImg: stephanie,
        blogAuthor: 'Stephanie Balint',
        blogPublishDate: '7th August 2024',
        blogLink: '/blog-volunteering'
    },
    {
        blogID:3,
        blogImg: LibraryAtTheDock, 
        blogTag:'Lifestyle', 
        blogTitle:'Stint\'s 5 Best Study Places to Help You Get Out of Your Study Funk', 
        blogDescription:'Studying at home getting you down in the dumps? Want to find a new place to refresh your mind and to get you in the zone? Well Stint has got your back! We have come up with five must-gos for students who are looking for a new space to sit and work in. Ranging from quiet, underrated locations to exciting, spirited cafes, Stint’s got everything covered for you.',
        blogAuthorImg: Ashley,
        blogAuthor: 'Ashley Yeow',
        blogPublishDate: '30th October 2023',
        blogLink: '/blog-studyPlaces'
    },
    {
      blogID:2,
      blogImg: Sequoia, 
      blogTag:'Events', 
      blogTitle:'If there is one thing you should take away from this week, it’s diversify', 
      blogDescription:'Well sure, the speakers during the Stint and Sequoia Career Week may have been talking about investment portfolios and how newbies should approach purchasing shares and stocks, but personally this tidbit really holds a lot of potential in so many spaces.',
      blogAuthorImg: Ashley,
      blogAuthor: 'Ashley Yeow',
      blogPublishDate: '27th February 2023',
      blogLink: '/blog-diversify'
    },
    {
        blogID:1,
        blogImg: BruceYovelaImage, 
        blogTag:'Community', 
        blogTitle:'How Bruce and Yovela Found Friendship Through Stint', 
        blogDescription:'Bruce Doan was a second year finance major student at The University of Melbourne. Yovela Budiman was a first year computing and software systems major student at The University of Melbourne. They both met each other through Stint - Australia’s biggest community for job seeking international students.',
        blogAuthorImg: BruceYovelaAuthor,
        blogAuthor: 'Bruce Doan',
        blogPublishDate: '13th November 2022',
        blogLink: '/blog-Bruce&Yovela'   
    }
]

export default blogList;