import React from 'react';
import Offering from '../components/EmployerHome/Offering/Offering';
import FAQ from '../components/FAQs/FAQ';
import StudentTestimonial from '../components/Testimonial/StudentTestimonial';
import EmployerTestimonial from '../components/Testimonial/EmployerTestimonial';

const Tester = () => {
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>
        <div>
            <Offering />
            {FAQ({type: "student"})}
        </div>
    </div>
    );
  };

export default Tester;