// Image Imports
import NathanAuthor from '../assets/Images/NathanAuthor.jpg'
import Hire from '../assets/Images/Hire.png'
import Will from '../assets/Images/Will.jpg'
import why from "../assets/Images/Why.png"
import skills from "../assets/Images/skills.png"
import Paul from "../assets/Images/Paul.jpg"

const blogList = [
    {
        blogID:3,
        blogImg: skills, 
        blogTag:'Hiring', 
        blogTitle:'The hidden skills and qualities of International students', 
        blogDescription: 'When working with International students, as a student advisor, I attend a lot of Employment expos and am constantly astounded at the lack of companies wishing to take on recently graduated International students.',
        blogAuthorImg: Paul,
        blogAuthor: 'Paul Florentine ',
        blogPublishDate: '5th September 2024',
        blogLink: '/insights-skills'   
    },
    {
        blogID:2,
        blogImg: why, 
        blogTag:'Hiring', 
        blogTitle:'Why Australian Employers Should Hire International Students and Graduates', 
        blogDescription:'In an increasingly globalised world, Australian businesses are challenged to stay competitive, innovative, and responsive to the needs of diverse markets. One significant, yet often overlooked, opportunity lies in tapping into the wealth of talent provided by international students and graduates. Despite some lingering misconceptions and hesitations, the benefits of hiring these individuals are profound and far-reaching.',
        blogAuthorImg: Will,
        blogAuthor: 'Will Phelan',
        blogPublishDate: '5th September 2024',
        blogLink: '/insights-why'   
    },
    {
        blogID:1,
        blogImg: Hire, 
        blogTag:'Hiring', 
        blogTitle:'5 Reasons Why Hiring International Students Can Give Your Business a Competitive Edge', 
        blogDescription:'Has your company ever considered hiring international students? If not, there are numerous reasons why you should. In this blog, we’ll explore 5 key benefits, from their multilingual skills to cultural fluency and diverse perspectives.',
        blogAuthorImg: NathanAuthor,
        blogAuthor: 'Nathan Lee',
        blogPublishDate: '20th July 2024',
        blogLink: '/insights-hiring'   
    }
]

export default blogList;