import Title from "../../components/Blogs/title";
import SadieAuthor from '../../assets/Images/SadieSaidAuthor.jpg'
import { useEffect } from "react";
const IntlResumeTips = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/ResumeTips.jpg')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        {/* Credits: Ailene Susanto */}
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "Resume Tips for International Students", 
                        "Coming to Australia as an International Student is an exciting time! There’s a constant stream of new people to meet, and the opportunity to max out your learning - Every. Single. Day.",
                        "Sadie Said", 
                        SadieAuthor, 
                        "16th August 2024")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        
                        Coming to Australia as an International Student is an exciting time! There’s a constant stream of new people to meet, and the opportunity to max out your learning - Every. Single. Day.<br></br><br></br>
                        It can also be tough…you’re away from family, friends and what feels familiar. There’s also the added difficulty that comes with building a career as an International Student. For some, this can feel impossible at times.<br></br><br></br>
                        The Australian job market is a fickle beast, recruiters and hiring managers are busy and the process of applying for jobs can feel confusing. You might be constantly questioning whether you’re doing it right?!<br></br><br></br> 
                        I’m here to tell you to keep going, consistency is helpful and also, to give you three of my best resume-writing tips for International Students looking to build their career in Australia.<br></br><br></br><br></br><br></br>



                        <span class = "font-extrabold">1. It’s OK to talk about yourself</span><br></br>
                        There isn’t a recruiter out there, looking at resumes, thinking: ‘Wow, this person likes to brag, don’t they?’ It might feel weird to pack your resume full of examples of how great you are at your work, however, that’s what recruiters need. It’s expected, and OK. <br></br><br></br>
                        Your previous work experience can’t speak for itself, and no one is going to tell you you’re a bad person, because you positively promoted yourself. <br></br><br></br>
                        Recruiters need evidence that you can do what you say you can do. And the easiest way to give them evidence is to provide examples and results of your efforts and success, in your overview and work experience sections. <br></br><br></br>
                        The best way to do this is to use numbers, stats, results, and achievement-focused statements. While watching your writing tone - you’re not pleading your case by listing all the tasks you did in your previous roles, you’re showcasing the results you got, as evidence you have the skills. <br></br><br></br><br></br>

                        <span style={{ textDecoration: "underline" }}>For example</span>: <br></br>
                        Don’t: I’m a highly motivated, customer-centric, sales professional. <br></br><br></br>
                        Do: I led a customer satisfaction project, uplifting customer NPS by 15% in 12 months, by training employees and creating a new process for greeting customers on calls.<br></br><br></br>
                        or <br></br><br></br>
                        Do: I have a customer NPS of +40, servicing all phone and chat-based SLAs on average, above expected. <br></br><br></br><br></br><br></br>





                        {/* <span className="font-extrabold">TL;DR</span>: To craft LinkedIn connection requests that work, follow these seven principles:<br></br>
                        <span style={{ marginLeft: "20px" }}>1. Use people’s names.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>2. Find common ground.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>3. Show genuine interest.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>4. Be authentic.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>5. Add value.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>6. Build credibility.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>7. Accept and move on.<br></br><br></br></span> */}



                        <span class = "font-extrabold">2. Context is King!</span><br></br>
                        Recruiters know a lot of company names, they even know what several 1000 companies do… in Australia. The knowledge they have though, isn’t infinite, and knowing what the company you worked for did, at a glance, is more helpful than you may know. Australian recruiters and hiring managers may not know about the company you worked for in your home country, best make it easy and tell them. <br></br><br></br>
                        Providing a one-sentence overview of a company gives a recruiter or hiring manager context as to the work you did, and how relevant that work is to the job you’re applying for. <br></br><br></br>
                        Saving a recruiter time will always win you points. A recruiter doesn’t have time and cognitive capacity (they’re already at max. capacity with the amount of context switching they do all day), to look up what a company does. This is especially critical if you’ve worked in tech. <br></br><br></br>
                        On your resume, under where you list the company’s name and your job title, provide one sentence summarising what the company does - things like industry, size, sector, etc. plus, a one-sentence summary of how you contributed to the company - things like the size of the team you led, type of projects you worked on, whether you partnered or collaborated with stakeholders, etc. <br></br><br></br><br></br>

                        <span style={{ textDecoration: "underline" }}>For example</span>: <br></br>
                        Data Analysis | March 2022 - December 2023<br></br>
                        Company ABC <br></br>
                        A global, software-as-a-service (SaaS) provider, servicing government and labour contracts with X, Y and Z. I partnered with Executives on internal audit projects, providing A, B, and C.<br></br><br></br><br></br><br></br>




                        <span class = "font-extrabold">3. Skills are skills… it doesn’t matter if you were paid while you learnt them. </span><br></br>
                        There was a time when doing volunteer work showed a certain character or personality that was looked at favourably by employers.<br></br><br></br>
                        While this is still the case, recruiters aren’t looking if you have or have not done volunteer work - they’re looking at whether you have the skills to do the job they’re recruiting for. If you happened to learn those skills while volunteering, great, you don’t need to tell the recruiter though. You only need to showcase you have the skills. <br></br><br></br>
                        This means that your resume will have a ‘Work Experience’ section that includes all the work experience you’ve had (that’s relevant to the role you’re applying for) - this includes work you’ve done in a full-time, paid capacity, or as a volunteer, in a casual, once a week capacity…and every iteration and type of work in between. <br></br><br></br>
                        You may also have a ‘Project Experience’ section, here, you can include any and all projects you’ve worked on…again, that are relevant to the role you’re applying for, and these projects don’t need to be ones you were paid to do. They can be projects you did at University, or during a Boot Camp course, or as part of a passion project. As long as you provide context and evidence of your achievements/results, this is valuable information for a recruiter.<br></br><br></br><br></br><br></br> 



                        {/* Divider line */}
                        <hr style={{ borderTop: "1px solid #ccc", marginTop: "20px", marginBottom: "20px" }} /><br></br>



                        If these tips have been helpful, you might benefit from further guidance via my online course, <a class = "text-blue-500 hover:text-blue-800" href = "https://neversaidanddone.thinkific.com/courses/ResumeReady_NEW">Resume Ready - For jobseekers new to the Australian job market</a>. <br></br><br></br>
                        This online course includes a section that goes into the nuances and intricacies of resume writing for the Australian job market, it helps you understand what makes you different and finally, teaches you exactly what you need to include in your resume.<br></br><br></br>
                        I created this online course for people just like you, after spending time coaching other International Students applying for jobs and building their careers here in Australia. <br></br><br></br><br></br>



                        {/* Divider line */}
                        <hr style={{ borderTop: "1px solid #ccc", marginTop: "20px", marginBottom: "20px" }} /><br></br>


                        <span class = "font-extrabold">About Sadie Said</span><br></br>
                        I help people write and talk about themselves, to get more of what they want in life. This includes helping people be visible in the Australian job market by providing communication guidance for resumes, portfolios, interviews, and networking. <br></br><br></br>
                        I have 15 years of experience in communications, from branding and PR to digital, web and social media. Most recently, I’ve worked for organisations in Internal Communications and Employee Experience. <br></br><br></br>
                        My clients are from a variety of backgrounds and industries, including mid-senior leaders, Executives and CEOs as well as Career Changers and people new to Australia. <br></br><br></br>
                        The best way to get in contact is via <a class = "text-blue-500 hover:text-blue-800" href = "https://www.linkedin.com/in/sadiesaid/">LinkedIn</a>. 

                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default IntlResumeTips;