import React from 'react';
import OfferingCard from './OfferingCard';

const Offering = () => {
  const offeringPlans = [
    {
      title: "Basic",
      price: "$39",
      priceNote: "one-time fee",
      featureStart: "What it includes:",
      features: [
        "Advertised on our job board with thousands of monthly visitors",
        "Shared with our 4,000+ community members",
        "Linked to your existing form or website for a seamless hiring process",
      ],
      note: "",
      popular: true
    },
    {
      title: "Featured",
      price: "$99",
      priceNote: "One-time fee",
      featureStart: "Everything in Basic, plus:",
      features: [
        "Featured listing at the top of our job board",
        "Targeted job email to our 4,000+ mailing list",
        "Social media post to our 10,000+ followers",
      ],
      // note: "Option: Upgrade to the Premium tier at the end of the process",
      // popular: true,
    },
    {
      title: "Premium",
      price: "$199",
      priceNote: "One-time fee",
      featureStart: "Everything in Basic and Featured, plus:",
      features: [
        "Initial resume screening of applicants",
        "Application form management",
        "Screening call with top applicants",
        "List of top applicants provided",
        "Featured company blog on our website"
      ],
      note: "",
    },
  ];

  return (
    <div className="bg-primary-purple min-h-screen min-w-screen flex items-center justify-center">
      <div className="max-w-full mx-auto p-10">
        <h1 className="text-white section-heading text-section-heading-mobile md:text-section-heading mb-6 text-center">
          A global business landscape needs international talent
        </h1>
        <p className="text-white text-center paragraph-one text-paragraph-one-mobile md:text-paragraph-one mb-10 md:mb-24">
          Find yours with Stint. List your roles and reach thousands of multilingual and culturally fluent candidates.
        </p>
        <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-6">
          {offeringPlans.map((plan, index) => (
            <OfferingCard
              key={index}
              title={plan.title}
              price={plan.price}
              priceNote={plan.priceNote}
              featureStart={plan.featureStart}
              features={plan.features}
              note={plan.note}
              popular={plan.popular}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Offering;
